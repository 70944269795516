import cookie          from "cookie";
import Cookies         from "js-cookie";
import _, { toString } from "lodash";
import { v1, v5 }      from "uuid";

import {
	getParamValueFromQueryString,
	updateURLParams
} from "_common/webview";
import {
	APP_VERSION,
	DEVICE_TYPE,
	NAMESPACE
} from "_config/index";

import sha256 from "./sha256";

import { checkIsBrowser } from ".";

const cookieExpiryInSecs = 60 * 60 * 24 * 30; // 30 days
const cookieExpiryInDays = 30; // 30 days

const USER_DATA_KEY = "user_auth";
const CDN_CACHE_ID  = "CDN-CACHE-IDENTITY";
const DARK_MODE     = "darkMode";
const allDomains    = [ "www.rooter.gg", "rdbcgn5bhyzg.rooter.gg", "snretyr3iueg.rooter.gg" ];

const getSHA256String = elements => {
	return sha256 ( elements.join ( "" ) );
};

export const getUserDataFromCookies = () => {
	const userDataFromCookies = getFromCookie ( USER_DATA_KEY );

	if ( !userDataFromCookies )
		return null;

	let data;

	try {
		data = JSON.parse ( userDataFromCookies );
	} catch ( e ) {
		data = userDataFromCookies;
	}

	return data;
};

export const getDeviceTheme = () => {
	const darkMode = getFromCookie ( DARK_MODE );

	if ( !darkMode || darkMode === "false" )
		return "light";

	return "dark";
};

export const isNewWebView = () => {
	const platform = getDeviceType ();

	if ( platform === "android" || platform === "ios" )
		return true;

	return false;
};

export const getUserId = () => {
	const userData = getUserDataFromCookies ();

	if ( userData?.userId )
		return userData?.userId;

	return getParamValueFromQueryString ( "userId" );
};

const storeUserDataCookie = data => {
	const __data = {
		accessToken  : data.accessToken,
		refreshToken : data.refreshToken,
		userId       : data.userId,
		isNew        : data.isNew,
		locale       : data.locale,
		tokenType    : data.tokenType,
		isVerified   : data.isVerified,
		userRoles    : data.userRoles,
		deviceId     : data?.deviceId,
		deviceType   : data?.deviceType,
		appVersion   : data?.appVersion
	};

	if ( checkIsBrowser () ) {
		storeUserData ( __data );

		// check if we are in old webview
		const isOldWebView = getParamValueFromQueryString ( "platform" );

		if ( !isNewWebView () && isOldWebView ) {

			if ( !( __data.accessToken && __data.refreshToken ) )
				return;

			// replace url when a refresh auth tokens call happens.
			const urlParams = updateURLParams ( __data );

			if ( isOldWebView === "android" ) {

				window.location.replace ( urlParams );

				return;

			}
			if ( isOldWebView === "ios" ) {

				/*
          window.location.replace does not work in iOS Safari.
          This is a workaround for iOS
        */
				const fullUrl        = window.location.href;
				const currentBaseUrl = fullUrl.split ( "?" )[0];

				// Update the query params here

				window.location.href = currentBaseUrl + urlParams;

				return;

			}

		}
	}

	return __data;
};

const USER_LOGIN_REQUEST = {
	/* OLD
  pushId   : "webPushId",
  locale   : "en",
  campaign : "webCampaign" */

	locale             : "en",
	thirdPartyCampaign : "webCampaign",
	pushId             : "webPushId"
};

const getDeviceId = () => {
	if ( !checkIsBrowser () )
		return v1 ();

	const userData = getUserDataFromCookies ();

	if ( userData?.deviceId )
		return userData?.deviceId;

	// get deviceId from URL instead of countless webview checks
	const webviewDeviceId = getParamValueFromQueryString ( "deviceId" );

	if ( webviewDeviceId )
		return webviewDeviceId;

	// if you have reached here, then there is no device id created
	// creating new and updating in cookies
	let newDeviceId = v1 ();

	storeUserData ( {
		...userData,
		deviceId: newDeviceId
	} );

	return newDeviceId;
};

const createAuthToken = data => {
	const deviceId    = getDeviceId ();
	const appSecret   = JSON.parse ( process.env.APP_SECRET_KEY );
	const appEnv      = process.env.APP_ENV;
	const __string    = [ appSecret[appEnv], deviceId, JSON.stringify ( data || USER_LOGIN_REQUEST ), appSecret[appEnv] ];
	const accessToken = getSHA256String ( __string );

	return {
		userAuth: {
			deviceId,
			accessToken
		}
	};
};

const checkFirstAndLastName = value => {
	let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;

	return regName.test ( value );
};

const getUserData = () => {
	if ( !checkIsBrowser () )
		return {};

	const userData = getUserDataFromCookies ();

	if ( userData && !_.isEmpty ( userData ) )
		return {
			accessToken  : userData?.accessToken,
			refreshToken : userData?.refreshToken,
			userId       : userData?.userId,
			locale       : userData?.locale,
			tokenType    : "Bearer"

		};

	// get token form query params, old webview system
	return {
		accessToken  : getParamValueFromQueryString ( "accessToken" ),
		refreshToken : getParamValueFromQueryString ( "refreshToken" ),
		userId       : getParamValueFromQueryString ( "userId" ),
		tokenType    : getParamValueFromQueryString ( "tokenType" )
	};
};

const storeUserData = userAuth => {
	if ( checkIsBrowser () )
		storeInCookie ( USER_DATA_KEY, JSON.stringify ( userAuth ), {
			expires  : cookieExpiryInDays,
			sameSite : "none",
			secure   : true,
			path     : "/",
			priority : "high",
			domain   : allDomains.includes ( window.location.hostname ) ? ".rooter.gg" : undefined
		} );

	return userAuth;
};

const createCacheKey = ( userId, isMobile, deviceType ) => {
	// This is for CDN, to identify which cached data to use,
	// or create a new one if no match

	let namespace;

	if ( deviceType === "android" || deviceType === "ios" )
		namespace = NAMESPACE[deviceType];
	else
		namespace = NAMESPACE[isMobile ? "mobile" : "desktop"];

	const uniqueKey = userId || "";

	return v5 ( String ( uniqueKey ), namespace );
};

const storeCacheId = ( userId, isMobile ) => {
	const cacheKey = createCacheKey ( userId, isMobile, "web" );

	storeInCookie ( CDN_CACHE_ID, cacheKey, { expires: cookieExpiryInDays } );

	return;
};

const storeDarkMode = darkMode => {
	if ( !checkIsBrowser () )
		return;

	storeInCookie ( DARK_MODE, toString ( darkMode ), {
		expires  : cookieExpiryInDays,
		sameSite : "none",
		secure   : true,
		path     : "/",
		priority : "high",
		domain   : allDomains.includes ( window.location.hostname ) ? ".rooter.gg" : undefined
	} );
};

const clearUserData = () => {
	Cookies.remove ( USER_DATA_KEY );
	Cookies.remove ( CDN_CACHE_ID );

	return;
};

export const getUserRolesFromCookies = () => {
	const userData = getUserDataFromCookies ();

	return userData?.userRoles;
};

const getDeviceType = () => {
	const userData = getUserDataFromCookies ();

	if ( userData?.deviceType === "android" || userData?.deviceType === "ios" )
		return userData?.deviceType;

	// get deviceType from URL instead of countless webview checks
	const webviewDeviceType = getParamValueFromQueryString ( "deviceType" ) || getParamValueFromQueryString ( "platform" );

	if ( webviewDeviceType )
		return webviewDeviceType;

	return DEVICE_TYPE;
};

const getAppVersion = () => {
	const userData = getUserDataFromCookies ();

	if ( userData?.appVersion )
		return userData?.appVersion;

	// get app version from URL instead of countless webview checks
	const webviewAppVer = getParamValueFromQueryString ( "appVersion" );

	if ( webviewAppVer )
		return webviewAppVer;

	return APP_VERSION;
};

const updateUserDataCookie = updatedData => {
	const userData = getUserDataFromCookies ();

	storeUserData ( {
		...userData,
		...updatedData
	} );
};

const serialisedSSRCookies = ( hostname, userAuth, isMobile, darkMode ) => {
	const cacheKey = createCacheKey ( userAuth?.userId, isMobile, userAuth?.deviceType );

	return [
		cookie.serialize ( USER_DATA_KEY, JSON.stringify ( userAuth ), {
			expires  : new Date ( 0 ),
			sameSite : "none",
			path     : "/",
			secure   : true,
			domain   : allDomains.includes ( hostname ) ? ".rooter.gg" : undefined
		} ),

		cookie.serialize ( DARK_MODE, toString ( darkMode ), {
			expires  : new Date ( 0 ),
			sameSite : "none",
			path     : "/",
			secure   : true,
			domain   : allDomains.includes ( hostname ) ? ".rooter.gg" : undefined
		} ),

		// New cookies here
		cookie.serialize ( USER_DATA_KEY, JSON.stringify ( userAuth ), {
			maxAge   : cookieExpiryInSecs,
			sameSite : "none",
			secure   : true,
			path     : "/",
			priority : "high",
			domain   : allDomains.includes ( hostname ) ? ".rooter.gg" : undefined
		} ),

		cookie.serialize ( DARK_MODE, toString ( darkMode ), {
			maxAge   : cookieExpiryInSecs,
			sameSite : "none",
			path     : "/",
			secure   : true,
			priority : "high",
			domain   : allDomains.includes ( hostname ) ? ".rooter.gg" : undefined
		} ),
		cookie.serialize ( CDN_CACHE_ID, cacheKey, {
			maxAge : cookieExpiryInSecs,
			path   : "/"
		} )
	];
};

const storeInCookie = ( key, value, options ) => Cookies.set ( key, value, options );

const getFromCookie = key => Cookies.get ( key );

export {
	storeDarkMode,
	USER_LOGIN_REQUEST,
	checkFirstAndLastName,
	clearUserData,
	createAuthToken,
	getAppVersion,
	getDeviceId,
	getDeviceType,
	getSHA256String,
	getUserData,
	storeUserData,
	updateUserDataCookie,
	storeCacheId,
	storeUserDataCookie,
	serialisedSSRCookies,
	getFromCookie,
	storeInCookie
};
